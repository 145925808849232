import { Grid, Paper, Typography } from "@mui/material";

const ServiceCard = ({title, description, imageUri}) => {
    const styles = {
        mainContainer: {
            padding: '15px',
            paddingTop: '25px',
            minHeight: '350px',
            cursor: 'pointer',
            transition: 'transform 0.3s, box-shadow 0.3s',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            "&:hover": {
                transform: 'translateY(-10px)', 
                boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.3)',
            }
        },
        image: {
            width: '100%',
            height: '190px'
        },
        titleText: {
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '50px'
        },
        descText: {
            fontSize: '13px',
            textAlign: 'center',
            marginTop: '10px'
        }
    }

    return (
        <Paper elevation={2} sx={styles.mainContainer}>
            <Grid container>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                    <img src={imageUri} style={styles.image}/>
                    <Typography sx={styles.titleText}>{title}</Typography>
                    <Typography sx={styles.descText}>{description}</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
};

export default ServiceCard;