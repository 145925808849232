import { Box, Grid, Typography } from "@mui/material";

import PathObject from "./pathObject";

const UserPath = () => {

    const styles = {
        mainContainer: {
            padding: '25px'
        },
        textContainer: {
            padding: '25px'
        },
        headingText: {
            fontSize: '40px',
            textAlign: 'center'
        }
    }

    return (
        <Box>
            <Grid container sx={styles.mainContainer}>
                <Grid item sm={12} md={12} lg={12} xl={12} sx={styles.textContainer}>
                    <Typography sx={styles.headingText}>Connecting Professionals Worldwide!</Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                    <PathObject />
                </Grid>
            </Grid>
        </Box>
    )
};

export default UserPath;