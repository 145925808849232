import { Grid, Typography } from "@mui/material";

const PathIconDetails = ({ icon, title, description }) => {
    const styles = {
        dataContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        icon: {
            width: '50px',
            height: '50px',
            backgroundColor: '#EDEDED',
            padding: '25px',
            borderRadius: '50%',
            overflow: 'visible'
        },
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px'
        },
        description: {
            fontSize: '16px',
            textAlign: 'center',
            marginTop: '15px',
            paddingLeft: '10px',
            paddingRight: '10px'
        }
    }
    
    return (
        <Grid container>
            <Grid item sm={12} md={12} lg={12} xl={12} sx={styles.dataContainer}>
                <img src={icon} style={styles.icon}/>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
                <Typography sx={styles.title}>{title}</Typography>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
                <Typography sx={styles.description}>{description}</Typography>
            </Grid>
        </Grid>
    )
};

export default PathIconDetails;