import { Box, Grid, Typography, Link } from "@mui/material";
import CopyrightIcon from '@mui/icons-material/Copyright';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

import FooterLinksComponent from "./footerLinksComponent";

const Footer = () => {
    const styles = {
        mainContainer: {
            backgroundColor:  '#1E4276',
            padding: '50px',
            height: '300px'
        },
        bottomContainer: {
            marginTop: '80px'
        },
        footerRightsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            color: 'white'
        },
        socialMediaIncon: {
            marginRight: '25px',
            cursor: 'pointer'
        },
        icon: {
            fontSize: '20px',
            marginBottom: '-4.5px'
        },
        socialMediaLink: {
            color: 'white',
            fontSize: '22px',
            "&:hover": {
                color: '#58758C'
            }
        },
        // socialMediaLink:hover: {
        //     fontSize: '24px',
        //     color: 'blue'
        // }
    }

    
    return (
        <Box sx={styles.mainContainer}>
            <Grid container>
               <Grid item sm={12} md={12} lg={12} xl={12}>
                    <FooterLinksComponent />
               </Grid>
            </Grid>
            <Grid container sx={styles.bottomContainer} spacing={3}>
                <Grid item sm={1} md={1} lg={1} xl={1}></Grid>
                <Grid item container sm={10} md={10} lg={10} xl={10} sx={styles.footerRightsContainer}>
                   <Typography><CopyrightIcon style={styles.icon} /> 2024 ConectYu. All rights reserved.</Typography>
                   <Grid item>
                        <Link href="https://www.instagram.com/conectyu" sx={styles.socialMediaLink}>
                            <InstagramIcon sx={styles.socialMediaIncon} />
                        </Link>
                        <Link href="https://twitter.com/ConectYu" sx={styles.socialMediaLink}>
                            <XIcon sx={styles.socialMediaIncon} />
                        </Link>
                   </Grid>
                </Grid>
                <Grid item sm={1} md={1} lg={1} xl={1}></Grid>
            </Grid>
        </Box>
    )
};

export default Footer;