import { Box, Grid, Typography } from "@mui/material";

import CollabComponent from "./collabComponent";

const ExploreComponent = () => {
    const styles = {
        boxContainer: {
            backgroundColor: '#EDEDED'

        },
        mainContainer: {
            padding: '25px'
        },
        textContainer: {
            padding: '25px'
        },
        headingText: {
            fontSize: '40px',
            textAlign: 'center',
            color: '#19A7CE',
            // fontFamily: 'Roboto',
            // fontStyle: 'normal'
        },
        hightlightText: {
            color: 'black'
        },
        dataContainer: {
            padding: '25px',
            paddingBottom: '100px'
        }
    }

    return (
        <Box sx={styles.boxContainer}>
            <Grid container sx={styles.mainContainer}>
                <Grid item sm={12} md={12} lg={12} xl={12} sx={styles.textContainer}>
                    <Typography sx={styles.headingText}>Connect <span style={styles.hightlightText}>With</span> us</Typography>
                </Grid>
            </Grid>
            <Grid container sx={styles.dataContainer}>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                    <CollabComponent />
                </Grid>
            </Grid>
        </Box>
    )
};

export default ExploreComponent;