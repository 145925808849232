import { Box, Grid, Typography } from "@mui/material"

const NoticeComponent = () => {
    const styles = {
        mainContainer: {
            backgroundColor:  '#EDEDED',
            padding: '50px'
        },
       dataComponent: {
            padding: '50px',
       },
        titleText: {
            fontSize: '24px',
            fontWeight: 'bold',
            width: '80%',
            marginLeft: '10%',
            marginTop: '10%'
        },
        descText: {
            textalign: 'justify',
            width: '80%',
            marginLeft: '10%',
            marginTop: '15px'
        },
        image: {
            width: '100%',
            height: '100%'
        }
    }
    return (
        <Box sx={styles.mainContainer}>
            <Grid container spacing={3}>
                <Grid item sm={8} md={8} lg={8} xl={8} >
                    <Typography sx={styles.titleText}>Elevate Your Professional Presence and Garner Attention</Typography>
                    <Typography sx={styles.descText}>
                        Discover in ConectYu the ultimate platform to curate a dynamic professional profile, spotlighting your 
                        skills, experiences, and successes. Distinguish yourself from the crowd and captivate leading companies 
                        in search of talent like yours.
                    </Typography>
                    <Typography sx={styles.descText}>
                        Seize command of your career trajectory now and unlock boundless avenues for progress and achievement.
                    </Typography>
                </Grid>
                <Grid item sm={4} md={4} lg={4} xl={4}>
                    <img src="./assets/laptopgirl.png" style={styles.image}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NoticeComponent;